import aguamineral from "../imagenes/bebidas/aguamineral.webp";
import cervezaCristal from "../imagenes/bebidas/cervezacristal.webp";
import jugosNaturales from "../imagenes/bebidas/jugoNaturales.webp";
import cervezaBucanero from "../imagenes/bebidas/cervezabucanero.webp";
import malteada from "../imagenes/bebidas/maltaBucanero.webp";
import refrescoEnlatado from "../imagenes/bebidas/refrescoenlatado.webp";
import coctelCamaron from "../imagenes/entrantes/coctelCamaron.webp";
import costillasAhumadas from "../imagenes/entrantes/costillasAhumadas.webp";
import ensaladaFria from "../imagenes/entrantes/ensaladaFria.webp";
import entremesJamonQueso from "../imagenes/entrantes/entremesJamonQueso.webp";
import eperlanPescado from "../imagenes/entrantes/eperlanPescado.webp";
import fajitasdeCerdo from "../imagenes/entrantes/fajitasdeCerdo.webp";
import frituraMalanga from "../imagenes/entrantes/frituraMalanga.webp";
import caipirisma from "../imagenes/tragos/caipirisma.webp";

export const projectsData = [
  {
    id: 1,
    name: "Agua Mineral 500ml",
    categoria: "bebidas",
    imageSrc: aguamineral,
    price: "$170",
    descripcion: "Agua mineral refrescante",
  },
  {
    id: 2,
    name: "Cerveza Cristal",
    categoria: "bebidas",
    imageSrc: cervezaCristal,
    price: "$250",
    descripcion:
      "La cerveza es una de las bebidas alcohólicas más antiguas y consumidas en el mundo. Entre las cubanas la Cristal y Bucanero son las preferidas.",
  },
  {
    id: 3,
    name: "Cerveza Bucanero",
    categoria: "bebidas",
    imageSrc: cervezaBucanero,
    price: "$250",
    descripcion: "...",
  },
  {
    id: 4,
    name: "Jugos Naturales",
    categoria: "bebidas",
    imageSrc: jugosNaturales,
    price: "$200",
    descripcion:
      "Dicen que los jugos pueden reducir el riesgo de cáncer, estimular el sistema inmunológico, eliminar los tóxicos del cuerpo, ayudar a la digestión ya perder peso. Nosotros en nuestro negocio contamos con varios sabores pensando siempre en su salud.",
  },
  {
    id: 5,
    name: "Malteada",
    categoria: "bebidas",
    imageSrc: malteada,
    price: "$320",
    descripcion: "...",
  },
  {
    id: 6,
    name: "Refresco Enlatado 355ml",
    categoria: "bebidas",
    imageSrc: refrescoEnlatado,
    price: "$180",
    descripcion:
      "Refresco enlatado que pueden variar las ofertas por marcas y diferentes sabores",
  },
  {
    id: 7,
    name: "Coctel de Camaron",
    categoria: "entrantes",
    imageSrc: coctelCamaron,
    price: "$580",
    descripcion: "Camarón acompañado de una exquisita salsa rosa y aceitunas",
  },
  {
    id: 8,
    name: "Costillas Ahumadass",
    categoria: "entrantes",
    imageSrc: costillasAhumadas,
    price: "$980",
    descripcion: "...",
  },
  {
    id: 9,
    name: "Ensalada Fria",
    categoria: "entrantes",
    imageSrc: ensaladaFria,
    price: "$180",
    descripcion:
      "La ensalada fría con es especial para esos días calurosos y compartir con amistades y familiares acompañados de una excelente bebida.",
  },
  {
    id: 10,
    name: "Entremes de Jamon y Queso",
    categoria: "entrantes",
    imageSrc: entremesJamonQueso,
    price: "$680",
    descripcion: "...",
  },
  {
    id: 11,
    name: "Emperlan de pescado",
    categoria: "entrantes",
    imageSrc: eperlanPescado,
    price: "$700",
    descripcion:
      "Filete de pescado en corte juliana enharinado y acompañado de una excelente salsa rosa",
  },
  {
    id: 12,
    name: "Fajitas de Cerdo",
    categoria: "entrantes",
    imageSrc: fajitasdeCerdo,
    price: "$880",
    descripcion:
      "Imposible de renunciar a probar en su visita a nuestro negocio de excelente carne de cerdo en corte juliana empanizada",
  },
  {
    id: 13,
    name: "Frituras de Malanga",
    categoria: "entrantes",
    imageSrc: frituraMalanga,
    price: "$280",
    descripcion:
      "Excelentes buñuelos de malanga fritos y crujientes para deleitar nuestro paladar.",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
  {
    id: 14,
    name: "Caipirísima",
    categoria: "tragos",
    imageSrc: caipirisma,
    price: "$280",
    descripcion:
      "La Caipiríssima es una adaptación caribeña​ del tradicional cóctel brasileño caipiriña, elaborado con ron en lugar de cachaza. (HC Añejo Blanco, Limón, Azúcar)",
  },
];

export const categoriasData = [
  {
    id: 1,
    name: "Bebidas",
    categoria: "bebidas",
  },
  {
    id: 2,
    name: "Entrantes",
    categoria: "entrantes",
  },
  {
    id: 3,
    name: "Tragos",
    categoria: "tragos",
  },
  
];
