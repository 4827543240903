import { Modal } from "flowbite-react";

const DetallesLocal = ({ isOpen, producto, onClose }) => {
  return (
    <>
      <Modal
        dismissible
        show={isOpen}
        onClose={onClose}
        position="center"
        className="pt-28 bg-opacity-30 bg-white "
      >
        
        {/* component */}

        <div className="fixed left-0 top-0 flex h-full  w-full items-center justify-center bg-black bg-opacity-50 py-10 pt-20">
          <div className="max-h-full w-11/12 min-w-80 max-w-xl overflow-y-auto rounded-3xl sm:w-full bg-white ">
            <div className="w-full ">
              <div className="m-2 my-12 max-w-[400px] lg:max-w-[560px] mx-auto mt-2">
                <div className="mb-8">
                  <div className="sticky top-[-1px] bg-white pt-2">
                    <div className="flex justify-between items-center  pb-4 px-6">
                      <h2 className="text-2xl font-semibold">
                        {producto.name}
                      </h2>
                      <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-x"
                        >
                          <line x1={18} y1={6} x2={6} y2={18} />
                          <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="max-w-md w-full lg:flex lg:max-w-[560px] ">
                    <div className="static flex items-end lg:items-center overflow-hidden rounded-3xl border-spacing-5 px-3  pb-4 lg:max-w-[240px]">
                      <img
                        className="rounded-xl"
                        src={producto.imageSrc}
                        alt={producto.imageAlt}
                      />
                    </div>
                    <div className="  bg-white rounded-b lg:rounded-b-none lg:rounded-r  flex flex-col justify-between leading-normal w-full">
                      <div className="mb-8 ml-3">
                        <p
                          className="text-grey-darker text-base text-justify mr-3"
                          dangerouslySetInnerHTML={{
                            __html: producto.descripcion.replace(
                              /\r\n/g,
                              "<br>"
                            ),
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                  <div className="text-lg font-bold text-main-500  px-4 pb- text-center">
                    Puede contactarnos mediante:
                  </div>
                  <div className="px-4 flex items-center mb-2 gap-1.5 hover:transform hover:scale-105 duration-300 ml-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      className="flex-none w-7 h-7 ml-1 "
                    >
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          className="bg-green-700"
                          id="whatsapp"
                          d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm6,21.87a8.47,8.47,0,0,1-6,2.48,8.34,8.34,0,0,1-4-1L7.5,24.5l1.2-4.39A8.47,8.47,0,1,1,22,21.87Zm-6-13a7,7,0,0,0-6,10.78l.17.26-.71,2.6,2.66-.7.26.15a7,7,0,0,0,10.62-6.05,7.05,7.05,0,0,0-7-7Zm4.13,10.06a2.17,2.17,0,0,1-1.42,1,2.82,2.82,0,0,1-1.33-.08c-.31-.1-.71-.23-1.21-.44a9.41,9.41,0,0,1-3.61-3.2A4,4,0,0,1,11.73,14a2.38,2.38,0,0,1,.74-1.77A.8.8,0,0,1,13,12h.4c.13,0,.31,0,.48.37s.6,1.46.65,1.57a.4.4,0,0,1,0,.37,1.55,1.55,0,0,1-.21.35c-.11.12-.23.28-.32.37s-.22.22-.09.43a6.17,6.17,0,0,0,1.17,1.47A5.94,5.94,0,0,0,16.84,18c.22.1.34.08.46-.06a8.92,8.92,0,0,0,.67-.83c.14-.21.28-.17.48-.1s1.23.58,1.44.69.36.16.41.24A1.8,1.8,0,0,1,20.17,18.91Z"
                          style={{ fill: "rgb(21, 128, 61)" }}
                        />
                      </g>
                    </svg>
                    <a
                      target="_blank"
                      className="pl-2 text-sm text-slate-500 truncate"
                      href="https://api.whatsapp.com/send?phone=+5355734432"
                    >
                      +53 55734432
                    </a>
                  </div>
                  <div className="px-4 flex items-center mb-2 gap-1.5 hover:transform hover:scale-105 duration-300 ml-16">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 32 32"
                      className="flex-none w-7 h-7 ml-1"
                    >
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path
                          id="phone"
                          d="M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm6.75,20.79a3.53,3.53,0,0,1-2.33,1.64,4.61,4.61,0,0,1-2.17-.14,18.46,18.46,0,0,1-2-.72,15.34,15.34,0,0,1-5.88-5.2A6.64,6.64,0,0,1,9,12.81a3.87,3.87,0,0,1,1.21-2.88,1.26,1.26,0,0,1,.91-.43l.66,0c.22,0,.5-.08.78.59s1,2.38,1.06,2.55a.65.65,0,0,1,0,.61,2.21,2.21,0,0,1-.34.57c-.18.2-.37.45-.52.6s-.35.36-.15.71a10.43,10.43,0,0,0,1.91,2.38,9.32,9.32,0,0,0,2.77,1.71c.35.17.55.15.75-.08s.86-1,1.09-1.35.46-.29.78-.18,2,.95,2.35,1.12.57.26.66.41A2.79,2.79,0,0,1,22.75,20.79Z"
                          style={{ fill: "rgb(61, 68, 255)" }}
                        />
                      </g>
                    </svg>
                    <a
                      target="_blank"
                      className="pl-2 text-sm text-slate-500 truncate"
                      href="tel://+5355734432"
                    >
                      +53 55734432
                    </a>
                  </div>
                  {/* Map Section Start */}
                  <section id="google-map-area">
                    <div className="mx-6 mb-6">
                      <div className="flex">
                        <div className="w-full">
                          {/* <object
                            style={{ border: 0, height: 450, width: "100%" }}
                            data="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2274.556000847507!2d-79.98430343751372!3d22.405309158649573!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2scu!4v1713126129794!5m2!1ses-419!2scu"
                          /> */}
                          <iframe
                            src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3688.639017375909!2d-79.9842745!3d22.404962!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2sus!4v1713127044429!5m2!1ses-419!2sus"
                            width="100%"
                            height={300}
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          />

                          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d2274.556000847507!2d-79.98430343751372!3d22.405309158649573!3m2!1i1024!2i768!4f13.1!5e0!3m2!1ses-419!2scu!4v1713126129794!5m2!1ses-419!2scu" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1517.7194421267477!2d-79.98437351644236!3d22.405128675668568!3m2!1i1024!2i768!4f13.1!5e1!3m2!1ses-419!2scu!4v1713125930820!5m2!1ses-419!2scu" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end items-center  pb-2 px-3 ">
                      <p className="text-sm text-grey-dark flex items-center">
                        <p className="text-xl text-grey-dark flex items-center font-bold">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-pin-map mr-4"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"
                            />
                          </svg>{" "}
                          {producto.ubicacion}
                        </p>
                      </p>
                    </div>
                  </section>
                  {/* Map Section End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DetallesLocal;
