import React, { useState } from "react";
import logo from "../imagenes/logo1.jpg";
import DetallesLocal from "./DetallesLocal";
import barweb from "../imagenes/bar1.webp";

const Info = () => {
  const [productModal, setProductModal] = useState({
    isOpen: false,
    product: null,
  });
  const openProductModal = () => {
    setProductModal({
      isOpen: true,
      product: {
        id: 1,
        name: "Bar Abgamez",
        href: "#",
        imageSrc: barweb,
        imageAlt: "Front of men's Basic Tee in black.",
        domicilio: "SI",
        descripcion:
          " En Bar Abgamez nos enorgullecemos de ofrecer una gran variedad de servicios para que puedas disfrutar de la mejor experiencia. Contamos con una extensa carta de comidas y bebidas para todos los gustos. Desde deliciosos aperitivos y sandwiches, hasta platos más sustanciosos y una selección de vinos y licores de primera.  Buscamos que te sientas como en casa desde el momento en que entras. Nuestros meseros están atentos a tus necesidades y te asesorarán sobre los mejores productos de nuestra carta.",
        ubicacion: "Santa Clara, Villa Clara",
      },
    });
  };
  return (
    <div className=" left-0 top-0 flex h-full  w-full items-center justify-center bg-green-50 pt-[110px]">
      <div className="max-h-full  min-w-80 w-full overflow-y-auto  sm:w-full bg-white ">
        <div className="w-full ">
          <div className="m-2 my-12 w-full mx-auto mt-2">
            <div className="mb-8">
              <button onClick={() => openProductModal()}>
                <div className="sticky top-[-1px] bg-white pt-2">
                  <div className="flex justify-center items-center  pb-4 px-6">
                    <div className="hover:transform hover:scale-105 duration-300 mr-5">
                      <img
                        className="h-14 w-auto"
                        src={logo}
                        alt="Logo SendCuba"
                      />
                    </div>
                    <h2 className="text-2xl font-semibold">
                      {/* <button onClick={() => openProductModal()}>
                      <span aria-hidden="true" className="absolute inset-0" /> */}
                      Bar Abgamez
                      {/* </button> */}
                    </h2>
                    {/* <button
                    // onClick={onClose}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x"
                    >
                      <line x1={18} y1={6} x2={6} y2={18} />
                      <line x1={6} y1={6} x2={18} y2={18} />
                    </svg>
                  </button> */}
                  </div>
                </div>
                <div className=" w-full sm:flex sm:max-w-[1024] ">
                  <div className="static flex items-end lg:items-center overflow-hidden rounded-3xl border-spacing-5 px-3  pb-4 lg:max-w-[360px] lg:max-h-[340px]">
                    <img className="rounded-xl" src={barweb} alt="alt" />
                  </div>

                  <section className="px-4 flex items-center text-main-500">
                    <div className="flex flex-1 flex-col">
                      <div>
                        <div className="flex-1 mt-1">
                          {/* <h1 className="text-2xl font-bold">Bar Abgamez</h1> */}
                          <p className="text-sm text-gray-500 line-clamp-2 mt-1.5 text-justify">
                            En Bar Abgamez nos enorgullecemos de ofrecer una
                            gran variedad de servicios para que puedas disfrutar
                            de la mejor experiencia. Contamos con una extensa
                            carta de comidas y bebidas para todos los gustos.
                            Desde deliciosos aperitivos y sandwiches, hasta
                            platos más sustanciosos y una selección de vinos y
                            licores de primera. Nuestro personal está altamente
                            capacitado para brindar un trato excepcional.
                            Buscamos que te sientas como en casa desde el
                            momento en que entras. Nuestros meseros están
                            atentos a tus necesidades y te asesorarán sobre los
                            mejores productos de nuestra carta. Además de contar
                            con un ambiente acogedor y música para amenizar,
                            ofrecemos servicio a domicilio para que puedas pedir
                            tus platos y bebidas favoritas sin necesidad de
                            salir de casa. En Bar Abgamez tu comodidad y
                            satisfacción son nuestra prioridad. Esperamos
                            recibir muy pronto tu visita para que vivas en carne
                            propia nuestro único estilo de atención cinco
                            estrellas. ¡Reserva ya tu mesa!
                          </p>
                          {/* <span className="text-sm font-semibold text-main-500">
                          Ver más...
                        </span> */}
                        </div>
                      </div>
                      {/* <div className="px-4 pt-4 flex gap-1">
                      <div className="flex items-center text-sm gap-1">
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          className="w-4 fill-main-500 stroke-main-500"
                        >
                          <path
                            clipRule="evenodd"
                            fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                          />
                        </svg>
                        <span className="font-semibold text-main-500">5</span>
                        <span className="text-slate-500">(4) reseñas</span>
                      </div>{" "}
                      ·{" "}
                      <div className="flex items-center gap-1 text-sm">
                        <span className="font-semibold text-main-500">CUP</span>
                        <span className="text-slate-500"> ($$ - $$$) </span>
                      </div>
                    </div> */}
                      <div className="flex flex-wrap gap-2 mt-4">
                        <div
                          // href="/b/alchimia-cosmetica-natural/info#address"
                          className="text-xs font-semibold bg-gray-700 rounded-md flex items-center py-1 px-2.5 text-white gap-1
                        hover:transform hover:scale-105 duration-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-pin-map"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8z"
                            />
                            <path
                              fillRule="evenodd"
                              d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6M4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"
                            />
                          </svg>{" "}
                          Santa Clara, Villa Clara
                        </div>
                        <div
                          className="text-xs font-semibold bg-green-700 rounded-md flex items-center py-1 px-2.5 text-white gap-1
                      hover:transform hover:scale-105 duration-300 "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            fill="currentColor"
                            className="bi bi-car-front-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2.52 3.515A2.5 2.5 0 0 1 4.82 2h6.362c1 0 1.904.596 2.298 1.515l.792 1.848c.075.175.21.319.38.404.5.25.855.715.965 1.262l.335 1.679q.05.242.049.49v.413c0 .814-.39 1.543-1 1.997V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.338c-1.292.048-2.745.088-4 .088s-2.708-.04-4-.088V13.5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1.892c-.61-.454-1-1.183-1-1.997v-.413a2.5 2.5 0 0 1 .049-.49l.335-1.68c.11-.546.465-1.012.964-1.261a.8.8 0 0 0 .381-.404l.792-1.848ZM3 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2m10 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2M6 8a1 1 0 0 0 0 2h4a1 1 0 1 0 0-2zM2.906 5.189a.51.51 0 0 0 .497.731c.91-.073 3.35-.17 4.597-.17s3.688.097 4.597.17a.51.51 0 0 0 .497-.731l-.956-1.913A.5.5 0 0 0 11.691 3H4.309a.5.5 0 0 0-.447.276L2.906 5.19Z" />
                          </svg>
                          {"  "}
                          Servicio domicilio
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {productModal.isOpen && (
        <DetallesLocal
          isOpen={productModal.product}
          producto={productModal.product}
          onClose={() => setProductModal({ isOpen: false })}
        />
      )}
    </div>
  );
};

export default Info;
