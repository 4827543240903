import { Modal } from "flowbite-react";

const DetallesProducto = ({ isOpen, producto, onClose }) => {
  return (
    <>
      <Modal
        dismissible
        show={isOpen}
        onClose={onClose}
        position="center"
        className="pt-28 bg-opacity-30 bg-white "
      >
        {/* component */}

        <div className="fixed left-0 top-0 flex h-full  w-full items-center justify-center bg-black bg-opacity-50 py-10 pt-20">
          <div className="max-h-full w-11/12 min-w-80 max-w-xl overflow-y-auto rounded-3xl sm:w-full bg-white ">
            <div className="w-full ">
              <div className="m-2 my-12 max-w-[400px] md:max-w-[560px] mx-auto mt-2">
                <div className="mb-8">
                  <div className="sticky top-[-1px] bg-white pt-2">
                    <div className="flex justify-between items-center  pb-4 px-6">
                      <h2 className="text-2xl font-semibold">
                        {producto.name}
                      </h2>
                      <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 focus:outline-none"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-x"
                        >
                          <line x1={18} y1={6} x2={6} y2={18} />
                          <line x1={6} y1={6} x2={18} y2={18} />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="max-w-md w-full md:flex md:max-w-[560px] ">
                    <div className="static flex items-center md:items-center overflow-hidden rounded-3xl border-spacing-5 px-3  pb-4 md:max-w-[240px]">
                      <img
                        className="rounded-xl m-auto"
                        src={producto.imageSrc}
                        alt={producto.imageAlt}
                      />
                    </div>
                    <div className="  bg-white rounded-b md:rounded-b-none md:rounded-r  flex flex-col justify-between leading-normal w-full">
                      <div className="mb-8 ml-3">
                        <p
                          className="text-grey-darker text-base pl-7"
                          dangerouslySetInnerHTML={{
                            __html: producto.descripcion.replace(/\r\n/g, "<br>"),
                          }}
                        ></p>
                        <div className="flex justify-end items-center  pb-2 px-6 py-6">
                          <p className="text-sm text-grey-dark flex items-center">
                            {/* <p className="text-xl text-grey-dark flex items-center font-bold">
                              {producto.provincia}
                            </p> */}
                          </p>
                          <p className=" text-grey-dark flex items-center font-bold text-xl md:pl-5">
                            {producto.price}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default DetallesProducto;
