import Navbar from "./components/navbar";
import { ProjectsProvider } from "./context/ProjectsContext";
import Productos from "./pages/Productos";
// Tareas pendientes del proyecto
// 1- Hacer el Admin(que tengo pensado ahcer un login general para todas las paginas
// 2- arreglar el mapa de la ubicacion para mostrar el icono de donde es el lugar
//3-separar por diferentes sessiones a la espera d la Api

function App() {
  return (
    <>
      <ProjectsProvider>
        <Navbar />
        <Productos />
      </ProjectsProvider>
    </>
  );
}

export default App;
