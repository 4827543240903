import { useContext, useEffect, useRef, useState } from "react";
import DetallesProducto from "./DetallesProduct";
import { ProjectsContext } from "../context/ProjectsContext";
import Info from "./Info";

export default function Productos() {
  const [productModal, setProductModal] = useState({
    isOpen: false,
    product: null,
  });

  const openProductModal = (product) => {
    setProductModal({
      isOpen: true,
      product,
    });
  };
  const { projects, searchProject, searchProjectsByTitle, categorias } =
    useContext(ProjectsContext);

  const section1 = useRef(null);
  const section2 = useRef(null);

  const [activeSection, setActiveSection] = useState("section1");

  useEffect(() => {
    const handleScroll = () => {
      updateActiveSection();
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  function updateActiveSection() {
    if (isSectionVisible(section1)) {
      setActiveSection("section1");
    }

    if (isSectionVisible(section2)) {
      setActiveSection("section2");
    }
  }

  function isSectionVisible(ref) {
    const section = ref.current;

    if (!section) return;

    const rect = section.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <>
      <div
        id="category-bar-list"
        className="bg-gray-200 shadow-top-bar fixed w-full mt-16 px-4 z-50 flex overflow-x-auto botones-categorias"
      >
        {categorias.map((cat) => (
          <a
            href={`#${cat.categoria}`}
            className={
              activeSection === ""
                ? "active flex-none px-3 mx-1 text-sm  py-3 font-normal text-black bg-gray-300 rounded-md  my-1"
                : "flex-none px-3 mx-1 text-sm  py-3 font-normal text-black border-black  my-1"
            }
          >
            {cat.name}
          </a>
        ))}
      </div>
      <Info />
      <div className=" bg-white">
        <div className="mx-auto  max-w-2xl px-6 py-1  lg:max-w-7xl lg:px-8">
          <h2 className="text-ml font-bold tracking-tight text-gray-500 ">
            {searchProject
              ? `Resultados de la busquedad de "${searchProject}" `
              : "Catálogo de productos"}
          </h2>
          {searchProject ? (
            searchProjectsByTitle.length === 0 ? (
              <div className="w-full text-center mt-10">
                <h2 className="text-2xl  font-bold tracking-tight text-center text-gray-500">
                  No tenemos este producto por ahora.
                </h2>
              </div>
            ) : (
              <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                {searchProjectsByTitle.map((product) => (
                  <button onClick={() => openProductModal(product)}>
                    <div className="group relative  rounded-xl bg-white p-2  hover:transform hover:scale-105 duration-300">
                      <div
                        className="rounded-lg border border-gray-50 bg-background-500 flex-none bg-contain w-full relative overflow-hidden pt-[100%]"
                        data-hash-bg="UhOenujE.TjdtkNeRPspPAIVRjWEwIWCIUR+"
                        style={{
                          backgroundImage: `url(${product.imageSrc})`,
                        }}
                      >
                        <div>
                          <img
                            src={product.imageSrc}
                            alt={product.imageAlt}
                            loading="lazy"
                            className="easeLoad absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg"
                            onload="this.style.opacity=1; "
                            style={{ opacity: 1 }}
                          />
                        </div>
                      </div>
                      <div className="mt-4 flex justify-between">
                        <div>
                          <h3 className="text-sm text-gray-700">
                            {product.name}
                          </h3>
                          <p className="mt-1 text-sm text-gray-500 descrip">
                            {product.descripcion}
                          </p>
                        </div>
                      </div>
                      <div className="text-end">
                        <p className="text-base font-medium text-gray-900">
                          {product.price}
                        </p>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            )
          ) : (
            categorias.map((cat) => (
              <div>
                <section
                  key={cat.id}
                  // ref={cat.categoria}
                  id={`${cat.categoria}`}
                  className="pt-20 -mt-20 "
                >
                  <h1 className="text-xl font-bold tracking-tight text-gray-500 mt-12">
                    {cat.name}
                  </h1>
                  <div
                    key={cat.id}
                    className="mt-6 grid grid-cols-2 gap-x-2 gap-y-2 sm:grid-cols-3 lg:grid-cols-4 xl:gap-x-8"
                  >
                    {projects.map((product) => {
                      if (cat.categoria === product.categoria) {
                        return (
                          <button onClick={() => openProductModal(product)}>
                            <div className="group relative  rounded-xl bg-white p-2  hover:transform hover:scale-105 duration-300">
                              <div
                                className="rounded-lg border border-gray-50 bg-background-500 flex-none bg-contain w-full relative overflow-hidden pt-[100%]"
                                data-hash-bg="UhOenujE.TjdtkNeRPspPAIVRjWEwIWCIUR+"
                                style={{
                                  backgroundImage: `url(${product.imageSrc})`,
                                }}
                              >
                                <div>
                                  <img
                                    src={product.imageSrc}
                                    alt={product.imageAlt}
                                    loading="lazy"
                                    className="easeLoad absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded-lg"
                                    onload="this.style.opacity=1; "
                                    style={{ opacity: 1 }}
                                  />
                                </div>
                              </div>{" "}
                              <div className="mt-4 flex justify-between">
                                <div>
                                  <h3 className="text-sm text-gray-700">
                                    {product.name}
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500 descrip text-start">
                                    {product.descripcion}
                                  </p>
                                </div>
                              </div>
                              <div className="text-end">
                                <p className="text-base font-medium text-gray-900">
                                  {product.price}
                                </p>
                              </div>
                            </div>
                          </button>

                          // </section>
                        );
                      }
                    })}
                  </div>
                </section>
              </div>
            ))
          )}
        </div>
      </div>

      {productModal.isOpen && (
        <DetallesProducto
          isOpen={productModal.product}
          producto={productModal.product}
          onClose={() => setProductModal({ isOpen: false })}
        />
      )}
    </>
  );
}
