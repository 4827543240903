import { ProjectsContext } from "../context/ProjectsContext";
import { useContext, useState } from "react";
import { Disclosure } from "@headlessui/react";
import logo from "../imagenes/logo1.jpg";
import barweb from "../imagenes/bar1.webp";
import DetallesLocal from "../pages/DetallesLocal";

export default function Navbar() {
  const {
    projects,
    searchProject,
    setSearchProject,
    searchProjectsByTitle,
    selectProject,
    setSelectProject,
    selectProjectsByCategory,
  } = useContext(ProjectsContext);

  const [productModal, setProductModal] = useState({
    isOpen: false,
    product: null,
  });
  const openProductModal = () => {
    setProductModal({
      isOpen: true,
      product: {
        id: 1,
        name: "Bar Abgamez",
        href: "#",
        imageSrc: barweb,
        imageAlt: "Front of men's Basic Tee in black.",
        domicilio: "SI",
        descripcion:
          " En Bar Abgamez nos enorgullecemos de ofrecer una gran variedad de servicios para que puedas disfrutar de la mejor experiencia. Contamos con una extensa carta de comidas y bebidas para todos los gustos. Desde deliciosos aperitivos y sandwiches, hasta platos más sustanciosos y una selección de vinos y licores de primera.  Buscamos que te sientas como en casa desde el momento en que entras. Nuestros meseros están atentos a tus necesidades y te asesorarán sobre los mejores productos de nuestra carta.",
        ubicacion: "Santa Clara, Villa Clara",
      },
    });
  };

  return (
    <Disclosure as="nav" className="bg-gray-800 bg-opacity-15 shadow-2xl">
      <>
        <div className="mx-auto  px-2 sm:px-6 lg:px-8 fixed top-0 left-0 z-20 w-full border-b-4 bg-white  ">
          <div className="relative flex h-16 items-center justify-between">
            <div className="flex flex-1 ml-2 ">
              <div className="flex flex-shrink-0 ">
                <a
                  className="hover:transform hover:scale-105 duration-300"
                  href="#"
                >
                  <img className="h-14 w-auto" src={logo} alt="Logo SendCuba" />
                </a>
              </div>
            </div>

            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 bg-white sm:w-3/4 w-2/3">
              <>
                <input
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchProject(e.target.value);
                  }}
                  className="font-general-medium
                                pl-3
                                pr-1
                                sm:px-4
                                py-2
                                border
                            border-gray-200
                                dark:border-secondary-dark
                                rounded-lg
                                text-sm
                                sm:text-md
                                bg-secondary-light
                                dark:bg-ternary-dark
                                text-primary-dark
                                dark:text-ternary-light
                                w-10/12
                                "
                  id="name"
                  name="name"
                  type="search"
                  required=""
                  placeholder="Buscar Producto"
                  aria-label="Name"
                />
              </>
              <button
                className="block px-4 py-2 text-sm text-gray-700 "
                onClick={() => openProductModal()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-5 w-5"
                >
                  <path
                    d="M12,22A10,10,0,1,1,22,12,10,10,0,0,1,12,22Z"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 24, 51)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "1.8px",
                    }}
                  />
                  <path
                    d="M12,16.1V11.33"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 24, 51)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "1.8px",
                    }}
                  />
                  <path
                    d="M12,7.9h0"
                    style={{
                      fill: "none",
                      stroke: "rgb(0, 24, 51)",
                      strokeLinecap: "round",
                      strokeLinejoin: "round",
                      strokeWidth: "1.8px",
                    }}
                  />
                </svg>
              </button>
              <div className="hidden md:ml-6 md:block">
                <div className="flex space-x-4 items-center"></div>
              </div>
            </div>
          </div>
        </div>
        {productModal.isOpen && (
          <DetallesLocal
            isOpen={productModal.product}
            producto={productModal.product}
            onClose={() => setProductModal({ isOpen: false })}
          />
        )}
      </>
    </Disclosure>
  );
}
